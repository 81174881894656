import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15a326ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = ["disabled", "onClick"]
const _hoisted_3 = {
  key: 0,
  class: "iconfont icon-selected"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        class: _normalizeClass({ 'en-span': _ctx.currentIndex === index }),
        disabled: item.disabled,
        onClick: ($event: any) => (_ctx.changeItem(item.value, item.label, item.valueOf, index))
      }, [
        _createTextVNode(_toDisplayString(item.label), 1),
        (_ctx.currentIndex === index)
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}