import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3516f71f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "dropdown-list" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.inputFlag)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mask",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputFlagC && _ctx.inputFlagC(...args)))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_scrollbar, { class: "scroll-wrapper" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              onClick: ($event: any) => (_ctx.changeItem(item.areaCode))
            }, [
              _createElementVNode("span", null, "+" + _toDisplayString(item.areaCode), 1),
              _createElementVNode("span", null, _toDisplayString(item.cnName), 1)
            ], 8, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}